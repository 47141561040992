'use strict';

import App from 'App';
import Server from 'server/Server';
import Context from 'models/context/Context';
import RunTaskRequest from 'server/protocol/request/task/RunTask';
import LoadingMask from 'views/loading/LoadingMask';
import OutputTask from 'views/tasks/OutputTask';
import MenuItemView from 'views/tasks/MenuItem';

class RunTask extends OutputTask {

    initInnerTaskProperties(){
        this.taskType = 'ListTask';
        this.className = 'listTask';
    }

    getTaskTabs() {
        let tabs = super.getTaskTabs();
        tabs.push({
            id: 'run',
            name: 'Lancer',
            icon: 'file',
            enabled: true
        });
        return tabs;
    }

    addTaskOperationItems() {
        let iconPath = Server.getTokenedUrl('configuration/' + this.domainContext.getId() + '/image/highres,big/128,100,64,36/process');
        let printMenuItem = new MenuItemView({
            label: 'Lancer',
            iconPath: iconPath,
            actionType: 'run',
            id : 'run'
        });
        this.taskOperationMenu.push(printMenuItem);
    }

    launchProcess() {
        var recordValues = this.launcherView.getFieldsValues();
        //Allow to modify the values if needed before sending them to the server
        let runTaskRequest = new RunTaskRequest(this.domainContext, this.getConfig().getId());

        let recordContext = new Context();
        recordContext.setContextId('record');

        let panels = this.getConfig().getPanels();
        for(var i in panels) {
            var panel = panels[i];
            recordContext.addPanelFields(recordValues, panel.getId());
        }
        runTaskRequest.setRecordContext(recordContext);

        let filterContext = new Context();
        filterContext.setContextId('filter');
        runTaskRequest.setFilterContext(filterContext);

        let keyContext = new Context();
        keyContext.setContextId('key');
        runTaskRequest.setKeyContext(keyContext);

        return Server.performRequest(runTaskRequest);
    }

    onLaunchTabPressed(tab) {
        super.onLaunchTabPressed(tab);

        if (tab === 'launch') {
            //Call to server to generate document
            let me = this;
            let loadingReqId;
            LoadingMask.requestLoading('Lancement du processus...')
                .then(function(reqId){
                    loadingReqId = reqId;
                    return me.launchProcess();
                })
                .then(this.onProcessExecuted.bind(this))
                .catch(App.displayErrorMessage)
                .then(function(){ LoadingMask.hide(loadingReqId);});
        }
    }

    onProcessExecuted(result) {
        let message = result.message;
        if(!message) {
            message = {
                title: 'Succès',
                body: 'Le traitement a été effectué avec succès.'
            };
        } else {
            if(!message.title || message.title.length === 0) {
                message.title = 'Succès';
            }
            if(!message.body || message.title.body === 0) {
                message.title = 'Le traitement a été effectué avec succès.';
            }
        }
        App.displayCustomMessage(result.message, 'comment');
    }

    execute() {
        var me = this;
        let loadingReqId;
        LoadingMask.requestLoading('Lancement du processus...')
            .then((reqId) => {
                loadingReqId = reqId;
                return me.launchProcess();
            })
            .then(() => {
                me.onProcessExecuted.bind(me);
                me.trigger('SAIExecuteTaskResult','success');
            })
            .catch((e) => {
                App.displayErrorMessage(e);
                me.trigger('SAIExecuteTaskResult','error');
            })
            .then(function(){ LoadingMask.hide(loadingReqId);});
    }

    handleTaskHeaderAction(action, actionArguments) {
        if (action === 'run') {
            //Call to server to launch the process
            let me = this;
            let loadingReqId;
            LoadingMask.requestLoading('Lancement du processus...')
                .then(function(reqId){
                    loadingReqId = reqId;
                    return me.launchProcess();
                })
                .then(this.onProcessExecuted.bind(this))
                .catch(App.displayErrorMessage)
                .then(function(){ LoadingMask.hide(loadingReqId);});
        } else{
            super.handleTaskHeaderAction(action, actionArguments);
        }
    }

    render() {
        super.render();
        this.$el.addClass('run-task');
        this.addLaunchButton();
    }

    addLaunchButton() {
        let recordContainer = this.$el.find('.taskBody');
        recordContainer.append('<div class="launch-button"></div>');
        this.getIcon();

        let buttonDiv = this.$el.find('.launch-button');
        buttonDiv.on('click', this.onLaunchTabPressed.bind(this, 'launch'));
    }

    getIcon() {
        let container:JQuery<HTMLElement> = this.$el.find('.launch-button');
        let me = this;
        let imgSrc = Server.getTokenedUrl('configuration/' + this.domainContext.getId() + '/image/highres,big/128,100,64,36/sai-process-button');
        let prom = new Promise((resolve, reject) => {
            var xhr = new XMLHttpRequest();
            xhr.open('GET', imgSrc);
            xhr.onload = () => {
                resolve(xhr.response);
            };
            xhr.send();
        })
            .then((response) => me.handleGetImg([response,imgSrc])
            ).then((element:any) => {
                container.append(element);
                container.append('<div class="label icon">Lancer</div>');
                element.css('margin-left', (container.find('.label').width() - element.width())/2 + 'px');
            }).catch((error) => {
                container.append('<button type="button">Lancer</button>');
                console.error(error);
            }).finally(() => {
                let screenGroups = container.parent().find('.group');
                if(screenGroups.length > 0) {
                    let lastGroup = screenGroups[screenGroups.length-1];
                    let grpTop = $(lastGroup).position().top;
                    let grpHeight = $(lastGroup).height();
                    let containerTop = grpTop + grpHeight;
                    container.css('top', containerTop+'px');
                }
            });
    }

    handleGetImg([response, imgSrc]) {
        var me = this;
        return new Promise((resolve, reject) => {
            let jsonResult = undefined;
            try {
                jsonResult = JSON.parse(response);
            } catch(e) {
                // Nothing to do
            }
            if (jsonResult !== undefined && jsonResult.status === 404) {
                reject(new Error(JSON.parse(response).message.body));
            } else  if (response.indexOf('<svg') >= 0) {
                var randNum = Math.floor(Math.random() * 100000);
                response = response.replace(new RegExp('icon-shadow','g'),'icon-shadow-'+randNum);
                response = response.replace(new RegExp('background-clip','g'),'background-clip-'+randNum);
                var xmlSvg = $($.parseXML(response));
                var size = '50px';
                xmlSvg.find('svg').attr('width', size).attr('height',size);
                resolve(xmlSvg.find('svg'));
            } else {
                let imgElement = $('<img src="'+imgSrc+'"/>');
                resolve(imgElement);
            }
        });
    }
}

export { RunTask };
