'use strict';

import EnumView from 'views/record/subfields/Enum';
import DeviceUtils from '../../../utils/DeviceUtils';
import Context from '../../../models/context/Context';

class ListEnum extends EnumView {

    getCloseOnSelect (){
        return false;
    }

    onDOMUpdated (initialContext?:Context, isInitialUpdate?:boolean) {
        super.onDOMUpdated();
        if(DeviceUtils.isMobile()){
            this.$el.find('.select2-container--default .select2-search--inline .select2-search__field').hide();
        }

        if(!isInitialUpdate) {
            this.$el.find('select').val(this.fieldState.getValue().split(',')).trigger('change');
        }
    }

    getSelectOptions (){
        return {
            closeOnSelect: this.getCloseOnSelect(),
            minimumResultsForSearch : -1
        };
    }

    protected canSelectMultiple(): boolean {
        return true;
    }

    setModelValue (value) {
        this.fieldState.setValue(typeof value === 'object' ? value.join(',') : value);
    }
}

export default ListEnum;
