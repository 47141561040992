'use strict';

let $ = require('jquery');
import { SAIView } from  'Additions';
import 'bootstrap';
import StringUtils from 'utils/StringUtils';
import CClientConfiguration from 'parametrage/CClientConfiguration';
import ColorUtils from 'utils/ColorUtils';
import NotificationManager from '../../utils/notification/NotificationManager';

class MessagePopupView extends SAIView {
    protected message : any;
    protected operationColor : string;
    protected allowDismiss : boolean;
    protected errorEl : JQuery<HTMLElement>;
    protected loadingSpinner : JQuery<HTMLElement>;
    private buttons : any;

    constructor(options? : any) {
        options.domainContext = null;
        super(options);
    }

    initialize(options) {
        this.template = require('ejs-loader!templates/modalPopup/ModalPopup.ejs');
        this.btnTemplate = require('ejs-loader!templates/modalPopup/ModalPopupButton.ejs');

        this.checkMandatoryParameter(options, 'message', 'The Message to display');
        this.checkMandatoryParameter(options, 'type', 'The type of message to display (error, comment, warning)');
        this.message = options.message;

        this.buttons = [
            {
                role: 'cancel',
                btnText: 'Ok', //todo i18n
                operation: this.cancelAction.bind(this, false),
                visibilityFlag: true
            }
        ];

        if (options.type === 'warning') {
            this.operationColor = '#e0b300';
        } else if (options.type === 'error') {
            this.operationColor = '#800000';
        } else if (options.type === 'comment') {
            this.operationColor = '#668834';
        } else if(options.type === 'help') {
            this.operationColor = '#6fb9e8';
        } else {
            console.error('invalid mode ' + options.type + '. Switching to comment');
            this.operationColor = '#668834';
        }
        this.uniqId = StringUtils.guid();
        this.allowDismiss = options.allowDismiss === undefined? false : options.allowDismiss;
    }

    render() : any {
        this.checkInitialized();
        this.$el = $(this.template({
            operationTitle: this.message.title,
            operationColor: this.operationColor,
            logo: CClientConfiguration.getGlobalImage(CClientConfiguration.APP_LOGO_BLACK),
            uniqId: this.uniqId,
            allowDocs: false,
            hideSpinner: false,
            hideCloseButton: false,
            hideTitle: false,
            extraClass: 'user-message',
            allowDismiss: this.allowDismiss,
            colorUtils: ColorUtils
        }));

        this.errorEl = this.$el.find('.innerView');
        this.loadingSpinner = this.$el.find('.loadingSpinner');
        this.loadingSpinner.hide();
        this.errorEl.addClass('message');
        var content = $('<p>').appendTo(this.errorEl);
        let bodyMessage = this.message.body;
        bodyMessage = bodyMessage.replace(new RegExp('\\[N\\]\\[N\\]', 'g'), '<br><br>');
        bodyMessage = bodyMessage.replace(new RegExp('\\[N\\]', 'g'), ' ');
        content.append(bodyMessage);

        let expertMessage = this.message.expert;
        if (expertMessage !== undefined && expertMessage !== '') {
            expertMessage = expertMessage.replace(new RegExp('\\[N\\]', 'g'), '<br>');
            let questionMark = $('<i>');
            questionMark.addClass('fa fa-question-circle expert-info message');
            this.$el.find('.modal-body').prepend(questionMark);
            questionMark.on('click', this.toggleExpertMessage.bind(this));
            this.$el.find('.modal-body').append('<div class="expert-message">'+expertMessage+'</div>');
        }

        let userMessage = this.message.user;
        if (userMessage !== undefined && userMessage !== '') {
            userMessage = userMessage.replace(new RegExp('\\[N\\]', 'g'), '<br>');
            var userContent = $('<p>').appendTo(this.errorEl);
            userContent.append(userMessage);
        }

        var buttonsHolder = this.$el.find('.modal-footer');

        for (var i = 0; i < this.buttons.length; i++) {
            var current = this.buttons[i];
            if(current.visibilityFlag) {
                var toAdd = $(this.btnTemplate({
                    bootstrapClass: 'btn-success',
                    btnClass: 'recordFormAcceptBtn',
                    enableOnLoad: 'enableOnLoad',
                    disabled: '',
                    btnText: current.btnText,
                    role: current.role,
                    dismiss: current.dismissOnClick === false ? '' : 'data-dismiss="modal"'
                }));
                if (current.faIcon !== undefined) {
                    toAdd.find('i').addClass('fa '+current.faIcon);
                }

                toAdd.appendTo(buttonsHolder);
                toAdd.on('click', current.operation);
                toAdd.on('keyup', this.handleButtonKeyup.bind(this));
            }
        }
    }

    toggleExpertMessage() {
        this.$el.find('.expert-message').toggle();
    }

    display() : Promise<string> {
        var me = this;

        //rendering popup
        this.render();

        this.$el.modal('show');

        var promise = new Promise<string>((accept,reject) => {
            this.$el.on('hidden.bs.modal', function () {
                $('#' + me.uniqId).remove();
                accept(undefined);
            });
        });
        return promise;
    }

    cancelAction() {
        this.trigger('modalWindowAction',NotificationManager.USER_CANCELLED);
    }

    private handleButtonKeyup(e: JQueryEventObject) {
        // space or enter
        if(e.which === 13 || e.which === 32) {
            $(e.target).click();
        }
    }
}

export default MessagePopupView;
