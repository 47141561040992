'use strict';

import DomainManager from 'DomainManager';
import Contexts from 'collections/Contexts';
import HeaderModel from 'models/Header';
import DeviceUtils from 'utils/DeviceUtils';
import HeaderView from 'views/tasks/Header';
import { SAIView } from '../../Additions';
import User from 'User';
import Server from 'server/Server';
import CClientConfiguration from 'parametrage/CClientConfiguration';
import MenuItemView from './MenuItem';
import DesktopActionsMenuView from './DesktopActionsMenu';
import { Task } from '../../parametrage/structures/Task';
import { DomainContext } from '../../parametrage/DomainContext';
import IntegerField from '../record/subfields/Integer';
import { Note } from '../../models/Note';
import App from 'App';
import NavigatorView from './navigators/simple/Navigator';

enum TaskPresentation {
    ShowNavigator = 1,
    ShowSubNavigator = 2,
    ShowPageTree = 4,
    ShowSideMenu = 8,
    ShowTaskTitle = 16,
    ShowRecordHeader = 32,
    ShowSubRecordHeader = 64,
    ShowPageHeader = 128,
    ShowGroupsHelp = 256
}

enum TaskWindowType {
    Normal,
    Simplified,
    FullSimplified,
    MicroPopup
}

const MAX_PRESENTATION = 511;

class TaskView extends SAIView {
    private readyState: Promise<boolean>;
    private taskConfig: Task;
    private acceptReadyState: any;
    public contexts: Contexts;
    public canCreate: boolean;
    public canDelete: boolean;
    public canModify: boolean;
    public canExecute: boolean;
    public canRead: boolean;
    public canReadPages: boolean;
    protected domainContext: DomainContext;
    private toggleState: string;
    protected taskOperationMenu: Array<any>;
    protected taskType: string;
    private taskInstanceId: string;

    private desktopActionsMenu: DesktopActionsMenuView;
    private taskHeader: HeaderView;
    private coloredTitle: JQuery<HTMLElement>;
    private presentation: number;
    protected recordKey: any;
    protected modalFlag: boolean = false;
    private notes: Array<Note>;
    protected navigator:NavigatorView;

    constructor(options){
        super(options);
        this.presentation = this.initPresentation();
        //We may have a general guideline on task visual definition
        if(options.windowType) {
            this.presentation &=  this.getRemovedPresentationFromType(options.windowType) ^ MAX_PRESENTATION;
        }
        //As well as some specific overrides
        if(options.presentation) {
            for(let i in options.presentation) {
                let flag = options.presentation[i];
                if(flag) {
                    this.presentation |= flag;
                } else {
                    let xorFlag = parseInt(i) ^ MAX_PRESENTATION;
                    this.presentation &= xorFlag;
                }
            }
            this.presentation |= options.presentation;
        }
        this.taskConfig = options.taskConfig;
        this.readyState = new Promise((accept, reject) => {
            this.acceptReadyState = accept;
        });
        this.contexts = new Contexts()
        /** Cache of access rights to avoid computation */
        var access = this.taskConfig.getAccess();
        var headerActions = this.taskConfig.getHeaderEnabledActions();
        this.canCreate = this.canCreateEntry(access,headerActions);
        this.canDelete = this.canDeleteEntry(access,headerActions);
        this.canModify = this.canModifyEntry(access,headerActions);
        this.canExecute = this.canExecuteEntry(access,headerActions);
        this.canRead = this.canReadEntry(access,headerActions);
        this.canReadPages = this.canReadPageEntry(this.taskConfig.getPagesAccess());
        //initialisation of task
        this.notes = [];
    }

    public static getWindowTypeFromString(type: string): TaskWindowType {
        switch(type) {
            case 'simplified':
                return TaskWindowType.Simplified;
            case 'full_simplified':
                return TaskWindowType.FullSimplified;
            case 'micro_popup':
                return TaskWindowType.MicroPopup;
        }
        return TaskWindowType.Normal;
    }

    protected initPresentation(): number {
        return TaskPresentation.ShowSideMenu | TaskPresentation.ShowTaskTitle | TaskPresentation.ShowGroupsHelp;
    }

    protected getRemovedPresentationFromType(type: TaskWindowType): number {
        switch(type) {
            case TaskWindowType.FullSimplified:
            case TaskWindowType.MicroPopup:
            case TaskWindowType.Simplified:
                return TaskPresentation.ShowSideMenu | TaskPresentation.ShowTaskTitle | TaskPresentation.ShowGroupsHelp;
        }
        return 0;
    }

    getViewType() {
        return 'task';
    }

    initialize (options) {
        this.domainContext = DomainManager.getCurrentContext(User.getCurrentDomain()).clone();
        this.options = options;

        this.initInnerTaskProperties();
        let readableType = options.taskConfig.getReadableType();
        this.template = require('ejs-loader!templates/task/' + readableType + '.ejs');

        this.postProcessModel();

        // We initialize the header bar of the task
        var newModel = new HeaderModel({
            taskName: options.taskConfig.getLabel(),
            taskIcon: 'file' //we need to table
        });
        newModel.set('applogo', CClientConfiguration.getGlobalImage(CClientConfiguration.APP_LOGO_BLACK));
        newModel.set('canSimplifyInterface', this.canSimplifyInterface());
        this.taskHeader = new HeaderView({
            model: newModel,
            domainContext: this.domainContext
        });

        this.toggleState = 'closed';
        this.taskOperationMenu = [];
        let closeIconPath = Server.getTokenedUrl('configuration/' + this.domainContext.getId() + '/image/highres,big/128,100,64,36/sai-close');
        let closeMenuItem = new MenuItemView({
            label: 'Fermer la tâche',
            iconPath: closeIconPath,
            actionType: 'close',
            id : 'CLOSE'
        });
        this.taskOperationMenu.push(closeMenuItem);
        this.addTaskOperationItems();

        this.desktopActionsMenu = new DesktopActionsMenuView({
            actionsList: this.taskOperationMenu,
            toggleState: this.toggleState
        });
        this.listenTo(this.desktopActionsMenu, 'action', this.handleTaskHeaderAction.bind(this));
    }

    addTaskOperationItems(): void {

    }

    setDominantColor(color: string) {
        //Each task has to know what to do with it
    }

    handleOverrideProperties() {
        let overrideProps = this.taskConfig.getInitialPropsOverride();
        for(let prop in overrideProps) {
            if(prop === 'filter') {
            //    this.mergeFilterWithInitialProps(this.taskModel.get('filter').elements, this.taskModel.get('initialpropsOverride').filter);
            } else if(prop === 'filterId') {
            //    this.taskModel.set('gridFilterId',overrideProps[prop]);
            } else {
            //    this.taskModel.set(prop, overrideProps[prop]);
            }
        }
    }

    canSimplifyInterface() {
        return false;
    }

    mergeFilterWithInitialProps(filterFields, filterValues){
        for(let key in filterFields){
            for(let valKey in filterValues) {
                if(filterFields[key].datafieldId === filterValues[valKey].datafieldId) {
                    filterFields[key].label = filterValues[valKey].text;
                    filterFields[key].value = filterValues[valKey].value;
                }
            }
        }
    }

    initInnerTaskProperties(){
        this.taskType = 'Invalid';
    }

    render (){
        this.$el.html(this.template(this.getMainTemplateData()));

        this.coloredTitle = this.$el.find('.coloredTitle');

        this.$el.attr('data-id',this.taskConfig.getId());
        this.$el.attr('id', this.getTaskInstanceId());

        this.$el.addClass(this.className);
        this.$el.addClass('task-instance');

        if (this.options['extraClass']) {
            this.$el.addClass(this.options['extraClass']);
        }

        this.taskHeader.setElement(this.getTaskHeaderElement());
        if((this.presentation & TaskPresentation.ShowTaskTitle) !== TaskPresentation.ShowTaskTitle) {
            this.getTaskHeaderElement().hide();
        }

        this.taskHeader.render();
        this.appendHelpToTitle();

        //this.listenTo(this.taskHeader, 'action', this.handleTaskHeaderAction.bind(this));

        if (!DeviceUtils.isFullScreenPageNav()) {
            this.buildRightMenu(this.taskOperationMenu);
        }

        return this;
    }

    handleTaskHeaderAction (actionName, actionArguments){
        console.warn('no handler implemented yet');
    }

    getMainTemplateData (){
        return {
            uniqBaseId: this.taskConfig.getId(),
            className: this.className,
            taskName: this.taskConfig.getLabel(),
            coloredTitleText: this.taskConfig.getLabel(),
            displayMode: DeviceUtils.isFullScreenPageNav() ? 'tablet' : 'desktop'
        };
    }

    postProcessModel (){
        //Override in subclasses
    }

    onDOMUpdated () {
        var mode = DeviceUtils.isFullScreenPageNav() ? 'tablet' : 'desktop';
        this.resizeTask(mode);
    }

    resizeTask (mode){
        //Override in subclasses
    }

    getTaskHeaderElement (){
        return this.$el.find('.taskHeader');
    }

    closeTask(){

    }

    execute() {

    }

    getReadyState() {
        return this.readyState;
    }

    canCreateEntry(access: string, headerActions?: string) {
        return access.indexOf('N') >= 0 && (headerActions === undefined || headerActions.indexOf('N') >= 0);
    }

    canModifyEntry(access, headerActions?: string) {
        return access.indexOf('M') >= 0 && (headerActions === undefined || headerActions.indexOf('M') >= 0);
    }

    canDeleteEntry(access, headerActions?: string) {
        return access.indexOf('D') >= 0 && (headerActions === undefined || headerActions.indexOf('D') >= 0);
    }

    canReadEntry(access, headerActions?: string) {
        return access.indexOf('R') >= 0 && (headerActions === undefined || headerActions.indexOf('R') >= 0);
    }

    canExecuteEntry(access, headerActions?: string) {
        return access.indexOf('X') >= 0 && (headerActions === undefined || headerActions.indexOf('X') >= 0);
    }

    canReadPageEntry(access) {
        let canAccess = false;
        for(let pageId in access) {
            if(access[pageId].indexOf('R') >= 0) {
                canAccess = true;
                break;
            }
        }
        return canAccess;
    }

    getConfig() {
        return this.taskConfig;
    }

    toggleMenu() {
        let menu = this.$el.find('.desktop-page-menu');
        if (menu !== undefined) {
            if (menu.hasClass('closed')) {
                menu.removeClass('closed');
                this.toggleState = '';
            } else {
                menu.addClass('closed');
                this.toggleState = 'closed';
            }
        }
    }

    buildRightMenu(items) {
        if (!this.desktopActionsMenu) {
            this.desktopActionsMenu = new DesktopActionsMenuView({
                actionsList: items,
                toggleState: this.toggleState
            });
        }

        let rightMenu = this.$el.find('.desktop-page-menu');
        if (rightMenu.length > 0) {
            rightMenu.remove();
        }
        this.desktopActionsMenu.setActionsList(items);
        rightMenu = this.desktopActionsMenu.render();
        if((this.presentation & TaskPresentation.ShowSideMenu) !== TaskPresentation.ShowSideMenu) {
            rightMenu.hide();
        }
        this.$el.append(rightMenu);
    }

    makeMainRecordRetreivalCall(key?: any) {
        //Override in subclasses
    }

    getKeys():any {
        //Override in subclasses
    }

    createNewEntry(initialValues?, initialize?): Promise<void> {
        //Override in subclasses
        return new Promise((accept) => {
            accept();
        })
    }

    isModal():boolean {
        return this.modalFlag;
    }

    setModal(flag: boolean) {
        this.modalFlag = flag;
    }

    setTaskInstanceId(id: string): void {
        this.taskInstanceId = id;
    }

    getTaskInstanceId(): string {
        return this.taskInstanceId;
    }

    public displayPanel(panelId: string): void {}
    
    getDatafieldPanelId(datafieldId: string) : string {
        //Ovveride in subclasses
        return undefined;
    }

    protected initializeConfig() {
        return new Promise((accept, reject) => {
            accept(undefined);
        });
    }

    public setNotes(notes:Array<Note>):void {
        this.notes = notes;
    }

    public getNotes():Array<any> {
        return this.notes;
    }

    public addNote(note:any) {
        this.notes.push(note);
    }

    private appendHelpToTitle():void {
        let taskHelp = this.getConfig().getConfig()['help'];
        if(taskHelp !== undefined && taskHelp !== '') {
            let displayHelpDesktop = App.getConfig().displayHelpDesktop;
            let displayHelpMobile = App.getConfig().displayHelpMobile;
            if((DeviceUtils.isMobile() && displayHelpMobile) || (!DeviceUtils.isMobile() && displayHelpDesktop)) {
                App.getSvgIcon(Server.getTokenedUrl('configuration/' + this.domainContext.getId() + '/image/highres,big/128,100,64,36/help-icon'),{h:15,w:15})
                .then((element: JQuery<HTMLElement>) => {
                    element.addClass('help-icon');
                    if(DeviceUtils.isMobile()) {
                        this.taskHeader.$el.find('.taskName').on('click', this.displayHelp.bind(this, taskHelp));
                    } else {
                        element.on('click', this.displayHelp.bind(this, taskHelp));
                    }
                    this.taskHeader.$el.find('.taskName').append(element);
                });
            }
        }
    }

    private displayHelp(text) {
        App.displayCustomMessage({title: 'Aide', body:text}, 'help');
    }

    public getNavigator(): NavigatorView {
        return this.navigator;
    }
}

export { TaskView, TaskPresentation, TaskWindowType };
