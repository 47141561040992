'use strict';

import * as _ from 'lodash';
let $ = require('jquery');
import Config from 'Config';
import {FieldFactory} from 'factory/FieldFactory';
import FieldUnit from 'factory/FieldUnit';
import { SAIView } from '../../Additions';
import FieldView from './Field';
import { Group } from '../../parametrage/structures/Group';
import { Field } from '../../parametrage/structures/Field';
import CoupleField from './subfields/Couple';
import App from 'App';
import Server from 'server/Server';
import DeviceUtils from '../../utils/DeviceUtils';

class GroupView extends SAIView {
    private fields : {[fieldId: string] : FieldView};
    private fieldsArr : Array<FieldView>;
    private title : any;
    private minLeft : number;
    private maxLeft : number;
    private domainContext : any;
    private displayMode: string;
    private groupConfig: Group;
    private enabled: boolean;

    constructor(options? : any) {
        super(options);
        this.fields = {};
        this.fieldsArr = [];
        this.enabled = options.enabled !== undefined ? options.enabled : true;
        this.groupConfig = options.groupConfig;
        this.domainContext = options.domainContext;
        this.fields = {};
        this.template = require('ejs-loader!templates/fields/Group.ejs');
        this.checkMandatoryParameter(options, 'displayMode', 'tablet or desktop based on screen size and context');
        this.displayMode = options.displayMode || 'desktop';

        this.setFields(this.groupConfig.getFields());
    }

    render() : any {
        this.checkInitialized();

        var currentTitle = this.groupConfig.getLabel() ? this.groupConfig.getLabel() : 'None';
        this.$el.html(this.template({
            title: currentTitle,
            dominantColor: this.options.dominantColor ? this.options.dominantColor : Config.appTheme,
            titleHeight: this.displayMode === 'desktop' ? FieldUnit[this.displayMode].height : FieldUnit[this.displayMode].height - 10,
        }));

        this.title = this.$el.find('.groupTitle');
        this.appendHelpToTitle();

        this.$el.addClass('group');
        this.$el.addClass(this.displayMode);
        this.$el.attr('data-id', this.groupConfig.getId());

        this.renderFields();
    }

    setFields(fieldsConfig) {
        this.clearCurrentFields();

        for (var key in fieldsConfig) {
            var newField: Field = fieldsConfig[key];
            var newView: FieldView = FieldFactory.buildField(newField, this.displayMode, this.domainContext);
            newView.setEnabled(newView.isEnabled() && this.enabled);
            if (newView) {
                this.fields[newView.getState().getId()] = newView;
                this.fieldsArr.push(newView);
            }
            if(newField.isCouple()) {
                //We need to set its associated field
                let associatedFieldConfig = newField.getLinkedCouple().getLabelField();
                if(associatedFieldConfig.getId() === newField.getId()) {
                    associatedFieldConfig = newField.getLinkedCouple().getKeyField();
                }
                //The first field won't have his friend, the second one will
                if(this.fields[associatedFieldConfig.getId()] !== undefined) {
                    (newView as CoupleField).setAssociatedField(this.fields[associatedFieldConfig.getId()] as CoupleField);
                    (this.fields[associatedFieldConfig.getId()] as CoupleField).setAssociatedField(newView as CoupleField);
                }
            }
        }
    }

    clearCurrentFields() {
        this.fields = {};

    }

    renderFields() {
        var fieldContainer = this.$el.find('.fieldsContainer');
        fieldContainer.empty();
        for (var key in this.fields) {
            var currentField = this.fields[key];
            var newFieldEl = $('<div>').appendTo(fieldContainer);
            currentField.setElement(newFieldEl);
            currentField.render();
        }
        this.moveFieldsToPositions();
        for (var key2 in this.fields) {
            this.fields[key2].onDOMUpdated(null, true);
        }
    }

    moveFieldsToPositions() {
        this.minLeft = Number.MAX_VALUE;
        this.maxLeft = 0;
        FieldFactory.locateFieldInGroup(this, this.fields);
    }

    public getFieldMinLeft(){
        return this.minLeft;
    }

    public getFieldMaxLeft() {
        return this.maxLeft;
    }

    getFieldsValues() {
        var output = {};
        for (var key in this.fields) {
            let curField = this.fields[key];
            output[key] = {
                value: curField.getStringValue(),
                initialValue: curField.getInitialValue(),
                lastValue: curField.getLastValue(),
                lastNonEmptyValue: curField.getLastNonEmptyValue(),
                humanValue: curField.getHumanValue(),
                visible: curField.isVisible(),
                enabled: curField.isEnabled()
            };
            if (curField['getPendingDocuments']) {
                var docs = curField['getPendingDocuments']();
                if (docs) {
                    output[key].notes = docs;
                }
            }
        }
        return output;
    }

    getFields(filter): Array<FieldView> {
        return _.filter(this.fields, filter);
    }

    getOrderedFields(): Array<FieldView> {
        return this.fieldsArr;
    }

    getField(fieldId: string): FieldView {
        return this.fields[fieldId];
    }

    setDominantColor(color){
        if(this.title){
            this.title.css('border-bottom-color', color);
            this.title.children('.groupTitleText').css('color', color);
        }

        for (var key in this.fields) {
            this.fields[key].setDominantColor(color);
        }
    }

    public getConfig(): Group {
        return this.groupConfig;
    }

    public getDisplayMode(): string {
        return this.displayMode;
    }

    private appendHelpToTitle():void {
        let groupHelp = this.getConfig().getConfig()['help'];
        if(groupHelp !== undefined && groupHelp !== '') {
            let displayHelpDesktop = App.getConfig().displayHelpDesktop;
            let displayHelpMobile = App.getConfig().displayHelpMobile;
            if((DeviceUtils.isMobile() && displayHelpMobile) || (!DeviceUtils.isMobile() && displayHelpDesktop)) {
                App.getSvgIcon(Server.getTokenedUrl('configuration/' + this.domainContext.getId() + '/image/highres,big/128,100,64,36/help-icon'),{h:15,w:15})
                .then((element: JQuery<HTMLElement>) => {
                    if(this.title.find('.help-icon').length === 0) {
                        element.addClass('help-icon');
                        if(DeviceUtils.isMobile()) {
                            this.title.on('click', this.displayHelp.bind(this, groupHelp));
                        } else {
                            element.on('click', this.displayHelp.bind(this, groupHelp));
                        }
                        this.title.append(element);
                    }
                });
            }
        }
    }

    private displayHelp(text) {
        App.displayCustomMessage({title: 'Aide', body:text}, 'help');
    }
}

export default GroupView;
