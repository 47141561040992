'use strict';

import App from 'App';
import Server from 'server/Server';
import Context from 'models/context/Context';
// Use the legacy version for older browser compatibility
// https://github.com/mozilla/pdf.js/issues/14729
const PDFJS = require('pdfjs-dist/legacy/build/pdf.js');
import PrintFileRequest from 'server/protocol/request/task/PrintFile';
import LoadingMask from 'views/loading/LoadingMask';
import OutputTask from 'views/tasks/OutputTask';
import NoteDownload from 'views/album/documents/NoteDownload';
import PrintFiles from '../../models/PrintFiles';
import MenuItemView from 'views/tasks/MenuItem';

class ListTask extends OutputTask {

    initInnerTaskProperties(){
        this.taskType = 'ListTask';
        this.className = 'listTask';
    }

    getTaskTabs() {
        let tabs = super.getTaskTabs();
        tabs.push({
            id: 'launch',
            name: 'Générer',
            icon: 'file',
            enabled: true
        });
        return tabs;
    }

    addTaskOperationItems() {
        let printIconPath = Server.getTokenedUrl('configuration/' + this.domainContext.getId() + '/image/highres,big/128,100,64,36/file');
        let printMenuItem = new MenuItemView({
            label: 'Générer',
            iconPath: printIconPath,
            actionType: 'launch',
            id : 'launch'
        });
        this.taskOperationMenu.push(printMenuItem);
    }

    generateDocument() {
        var recordValues = this.launcherView.getFieldsValues();
        //Allow to modify the values if needed before sending them to the server
        let printFileRequest = new PrintFileRequest(this.domainContext, this.getConfig().getId());

        let recordContext = new Context();
        recordContext.setContextId('record');

        let panels = this.getConfig().getPanels();
        for(var i in panels) {
            var panel = panels[i];
            recordContext.addPanelFields(recordValues, panel.getId());
        }
        printFileRequest.setRecordContext(recordContext);

        let filterContext = new Context();
        filterContext.setContextId('filter');
        printFileRequest.setFilterContext(filterContext);

        let keyContext = new Context();
        keyContext.setContextId('key');
        printFileRequest.setKeyContext(keyContext);

        return Server.performRequest(printFileRequest)
            .then(function(response) {
                var pf = new PrintFiles(response.printId, response.files);
                var nd = new NoteDownload(pf.toNoteDownload());
                nd.display();
            });
    }

    onLaunchTabPressed(tab) {
        super.onLaunchTabPressed(tab);

        if (tab === 'launch') {
            //Call to server to generate document
            let me = this;
            let loadingReqId;
            LoadingMask.requestLoading('Génération du document...')
                .then(function(reqId){
                    loadingReqId = reqId;
                    return me.generateDocument();
                })
                .then(this.onDocumentGenerated.bind(this))
                .catch(App.displayErrorMessage)
                .then(function(){ LoadingMask.hide(loadingReqId);});
        }
    }

    onDocumentGenerated(result) {
    }

    loadCurrentView () {
        super.loadCurrentView();

        var value = this.selectBox.val();
        if (value !== null) { //User selected a view
            this.setLoadingViewMode();
            var current = this.currentViewProperties = this.gridMapping[value];
        }
    }

    onResultReceived (result){
        var me = this;
        var printParam = {
            printId: result.printId,
            filename: result.files[1].name,
            humanName: result.files[1].humanName
        };

        var fileParam = 'file='+JSON.stringify(printParam);
        var fetchUrl = window.App.getTokenedUrl('file/getResult.action?'+fileParam);
        var canvas = $('<canvas width="'+this.viewContainer.width()+'" height="500"></canvas>').appendTo(me.viewContainer);
        PDFJS.getDocument(fetchUrl).then(function(pdf) {
            //
            // Fetch the first page
            //
            pdf.getPage(1).then((page) => {
                var scale = 1.5;
                var viewport = page.getViewport({ scale: scale });
                // Support HiDPI-screens.
                var outputScale = window.devicePixelRatio || 1;
                //
                // Prepare canvas using PDF page dimensions
                //
                var context = canvas[0].getContext('2d');
                canvas.height = Math.floor(viewport.height * outputScale);
                canvas.width = Math.floor(viewport.width * outputScale);
                canvas.style.width = Math.floor(viewport.width) + 'px';
                canvas.style.height =  Math.floor(viewport.height) + 'px';

                var transform = outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;
                //
                // Render PDF page into canvas context
                //
                var renderContext = {
                    canvasContext: context,
                    transform: transform,
                    viewport: viewport
                };
                page.render(renderContext);
            });
        });
    }

    setModelValue(fieldId, datafieldId, value) {
        for (var i=0; i < this.launcherView.groups.length; i++) {
            var field = this.launcherView.groups[i].fields[fieldId];
            if(field) {
                field.setModelValue(value);
                field.render();
                return;
            }
        }
    }

    execute() {
        var me = this;
        let loadingReqId;
        LoadingMask.requestLoading('Génération du document...')
            .then((reqId) => {
                loadingReqId = reqId;
                return me.generateDocument();
            })
            .then(() => {
                me.onDocumentGenerated.bind(me);
                me.trigger('SAIExecuteTaskResult','success');
            })
            .catch((e) => {
                App.displayErrorMessage(e);
                me.trigger('SAIExecuteTaskResult','error');
            })
            .then(function(){ LoadingMask.hide(loadingReqId);});
    }

    handleTaskHeaderAction(action, actionArguments) {
        if (action === 'launch') {
            //Call to server to generate document
            let me = this;
            let loadingReqId;
            LoadingMask.requestLoading('Génération du document...')
                .then(function(reqId){
                    loadingReqId = reqId;
                    return me.generateDocument();
                })
                .then(this.onDocumentGenerated.bind(this))
                .catch(App.displayErrorMessage)
                .then(function(){ LoadingMask.hide(loadingReqId);});
        } else{
            super.handleTaskHeaderAction(action, actionArguments);
        }
    }

    render() {
        super.render();
        this.addLaunchButton();
    }

    addLaunchButton() {
        let recordContainer = this.$el.find('.taskBody');
        recordContainer.append('<div class="launch-button"></div>');
        this.getIcon();

        let buttonDiv = this.$el.find('.launch-button');
        buttonDiv.on('click', this.onLaunchTabPressed.bind(this, 'launch'));
    }

    getIcon() {
        let container = this.$el.find('.launch-button');
        let me = this;
        let imgSrc = Server.getTokenedUrl('configuration/' + this.domainContext.getId() + '/image/highres,big/128,100,64,36/sai-print-button');
        let prom = new Promise((resolve, reject) => {
            var xhr = new XMLHttpRequest();
            xhr.open('GET', imgSrc);
            xhr.onload = () => {
                resolve(xhr.response);
            };
            xhr.send();
        })
            .then((response) => me.handleGetImg([response,imgSrc])
            ).then((element) => {
                container.append(element);
                container.append('<div class="label icon">Générer</div>');
                element.css('margin-left', (container.find('.label').width() - element.width())/2 + 'px');
            }).catch((error) => {
                container.append('<button type="button">Générer</button>');
                console.error(error);
            }).finally(() => {
                let screenGroups = container.parent().find('.group');
                if(screenGroups.length > 0) {
                    let lastGroup = screenGroups[screenGroups.length-1];
                    let grpTop = $(lastGroup).position().top;
                    let grpHeight = $(lastGroup).height();
                    let containerTop = grpTop + grpHeight;
                    container.css('top', containerTop+'px');
                }
            });
    }

    handleGetImg([response, imgSrc]) {
        var me = this;
        return new Promise((resolve, reject) => {
            let jsonResult = undefined;
            try {
                jsonResult = JSON.parse(response);
            } catch(e) {
                // Nothing to do
            }
            if (jsonResult !== undefined && jsonResult.status === 404) {
                reject(new Error(JSON.parse(response).message.body));
            } else  if (response.indexOf('<svg') >= 0) {
                var randNum = Math.floor(Math.random() * 100000);
                response = response.replace(new RegExp('icon-shadow','g'),'icon-shadow-'+randNum);
                response = response.replace(new RegExp('background-clip','g'),'background-clip-'+randNum);
                var xmlSvg = $($.parseXML(response));
                var size = '50px';
                xmlSvg.find('svg').attr('width', size).attr('height',size);
                resolve(xmlSvg.find('svg'));
            } else {
                let imgElement = $('<img src="'+imgSrc+'"/>');
                resolve(imgElement);
            }
        });
    }
}

export { ListTask };
