'use strict';

import App from 'App';
import { TaskView } from 'views/tasks/Task';
import { FilterPopupView } from 'views/tasks/navigators/simple/FilterPopupView';
import DeviceUtils from 'utils/DeviceUtils';
import _ from 'lodash';
import SAITabBarView from './TabBar';
import RecordView from '../record/Record';
import { Panel } from '../../parametrage/structures/Panel';
import { Field } from '../../parametrage/structures/Field';

class OutputTask extends TaskView {

    initialize(options) {
        super.initialize(options);

        var displayMode;

        if (DeviceUtils.isMediumDevice() || DeviceUtils.isSmallDevice()){
            displayMode = 'tablet';
        }else{
            displayMode = 'desktop';
        }

        var domiCol = options.meta ? options.meta.color : '#000000';

        this.launcherView = new RecordView({
            displayMode:displayMode,
            groups: options.taskConfig.getPanels()[0].getGroups(),
            taskConfig: options.taskConfig,
            contextType: 'view',
            domainContext: options.domainContext,
            dominantColor: domiCol,
            panelId: options.taskConfig.getPanels()[0].getId()
        });

        this.listenTo(this.launcherView, 'record.onPrepareScriptContext', this.getScriptContext.bind(this));
        this.listenTo(this.launcherView, 'record.onPrepareContext', this.onContextRequest.bind(this));
    }

    getScriptContext(context, withDollar) {
        let taskConfig = this.getConfig();
        context[(withDollar ? '$' : '') + 'CURRENT_TASK'] = taskConfig.getId();
        context[(withDollar ? '$' : '') + 'CURRENT_TASK_TABLE'] = taskConfig.getTableID();
    }

    onContextRequest(context) {
        context.addPanelFields(this.launcherView.getFieldsValues(), this.launcherView.panelId);
    }

    applyInitialScreen(panelScreen) {
        App.setActionMode('newEntry');
        let initialPanel = new Panel(panelScreen[0], null, this.domainContext);
        this.launcherView.setValuesAndPositions(initialPanel.getGroups(), true);
        //this.launcherView.render();
    }

    updateScreenContent(notificationScreen) {
        for(let screenEntry in notificationScreen){
            let someField = new Field(notificationScreen[screenEntry], null, null, null, this.domainContext);
            this.launcherView.setField(someField,true,true);
        }
        let groups = this.launcherView.getGroups();
        for(let i in groups) {
            groups[i].moveFieldsToPositions();
        }
    }

    getRecordViews() {
        return [this.launcherView];
    }

    initInnerTaskProperties(){
        this.taskType = 'Invalid';
        this.className = 'invalidTask';
    }

    render() {
        super.render();

        let tabBarEl = this.$el.find('.taskTabBar');
        let recordEl = this.$el.find('.recordContainer');
        this.launcherView.setElement(recordEl);

        if (DeviceUtils.isFullScreenPageNav()) {
            if (!this.launchTabBar) {
                var tabs = this.getTaskTabs();

                this.launchTabBar = new SAITabBarView({
                    el: tabBarEl,
                    prefix: 'listbar',
                    tabs: tabs,
                    dockLocation: 'bottom',
                    domainContext: this.domainContext
                });
            }

            this.launchTabBar.render();
            this.listenTo(this.launchTabBar, 'tabPressed', this.onLaunchTabPressed.bind(this));
            this.launchTabBar.$el.show();
            recordEl.height($(window).height() - tabBarEl.height());
        }

        this.$el.addClass('output-task');
        this.launcherView.render();
    }

    onLaunchTabPressed(tab) {
        if (tab === 'home') {
            window.App.backToDesktop();
        }
    }

    getTaskTabs() {
        return [
            {
                id: 'home',
                name: 'Home',
                icon: 'home',
                enabled: true
            }
        ];
    }

    getMainTemplateData() {
        return {
            selectLabel: 'Selection de la vue'
        };
    }

    parseExistingFilters() {
        this.filterValues = {};
        this.userCustomFilter = {};
        var grids = this.model.get('grids');
    }

    parseFilter(entry) {
        var base = _.cloneDeep(entry);
        if (base.value !== '') {
            base.finalStruct = this.parseFilterValue(base.value);
        }
        return base;
    }

    parseFilterValue(value) {
        var result = {
            type: 'EQ'
        };

        value = value.trim();

        if (value.indexOf(' OR ') > -1) {
            result.type = 'OR';
            result.subEntries = [];
            result.subEntries.push(this.parseFilterValue(value.substring(0, value.indexOf(' OR '))));
            result.subEntries.push(this.parseFilterValue(value.substring(value.indexOf(' OR ') + 3), value.length));
        } else if (value.indexOf(',') > -1) {
            result.type = 'OR';
            result.subEntries = [];
            result.subEntries.push(this.parseFilterValue(value.substring(0, value.indexOf(','))));
            result.subEntries.push(this.parseFilterValue(value.substring(value.indexOf(',') + 1), value.length));
        } else if (value.indexOf(' AND ') > -1) {
            result.type = 'AND';
            result.subEntries = [];
            result.subEntries.push(this.parseFilterValue(value.substring(0, value.indexOf(' AND '))));
            result.subEntries.push(this.parseFilterValue(value.substring(value.indexOf(' AND ') + 4), value.length));
        } else if (value.charAt(0) === '*' && value.charAt(value.length - 1) === '*') {
            result.type = 'CONTAINS';
            result.value = this.cleanValue(value.substring(1, value.length - 1));
        } else if (value.charAt(0) === '*') {
            result.type = 'STARTSWITH';
            result.value = this.cleanValue(value.substring(1));
        } else if (value.indexOf('NOT ') === 0 && value.charAt(value.length - 1) === '*') {
            result.type = 'NOTENDSWITH';
            result.value = this.cleanValue(value.substring(4, value.length - 1));
        } else if (value.charAt(value.length - 1) === '*') {
            result.type = 'ENDSWITH';
            result.value = this.cleanValue(value.substring(0, value.length - 1));
        } else if (value === 'IS NOT NULL' || value === 'NOT NULL') {
            result.type = 'NOTNULL';
        } else if (value === 'NULL') {
            result.type = 'ISNULL';
        } else if (value.indexOf('NOT *') === 0) {
            result.type = 'NOTSTARTSWITH';
            result.value = this.cleanValue(value.substring(5));
        } else if (value.indexOf('NOT ') === 0) {
            result.type = 'NOT';
            result.value = this.cleanValue(value.substring(4));
        } else if (value.indexOf('<>') === 0) {
            result.type = 'NOT';
            result.value = this.cleanValue(value.substring(2));
        } else if (value.indexOf('>=') === 0) {
            result.type = 'GE';
            result.value = this.cleanValue(value.substring(2));
        } else if (value.indexOf('>') === 0) {
            result.type = 'GT';
            result.value = this.cleanValue(value.substring(1));
        } else if (value.indexOf('<=') === 0) {
            result.type = 'LE';
            result.value = this.cleanValue(value.substring(2));
        } else if (value.indexOf('<') === 0) {
            result.type = 'LT';
            result.value = this.cleanValue(value.substring(1));
        } else if (value.indexOf('=') === 0) {
            result.type = 'EQ';
            result.value = this.cleanValue(value.substring(1));
        } else {
            result.value = this.cleanValue(value);
        }
        return result;
    }

    cleanValue(value) {
        value = value.trim();
        if (value.charAt(0) === '(' && value.charAt(value.length - 1) === ')') {
            return this.cleanValue(value.substring(1, value.length - 1));
        } else if (value.charAt(0) === '\'' && value.charAt(value.length - 1) === '\'') {
            return this.cleanValue(value.substring(1, value.length - 1));
        }
        return value;
    }

    loadCurrentView() {
        //Nothing to be done here for now
    }

    onFilterClicked() {
        var value = this.selectBox.val();
        if (value !== null) {
            var current = this.currentViewProperties = this.gridMapping[value];
            this.filterView = new FilterPopupView({
                taskId: this.model.get('taskId'),
                filters: this.filterValues[current.filterId],
                filterValues: current,
                callback: this.onFilterSet.bind(this)
            });
            this.filterView.display();

        }
    }

    onFilterSet() {
    }

    setLoadingViewMode() {
    }

    onGridReceived(response) {
        console.warn('nothing to do');
    }

    getReadyState() {
        this.acceptReadyState();
        return this.readyState;
    }

    handleTaskHeaderAction (action, actionArguments) {
        if (action === 'close') {
            window.App.popTask();
        } else{
            super.handleTaskHeaderAction(action, actionArguments);
        }
    }
}

export default OutputTask;
