'use strict';

import { TaskRequest } from './TaskRequest';
import { DomainContext } from '../../../../parametrage/DomainContext';

class RunTaskRequest extends TaskRequest{
    constructor(domain:DomainContext, task){
        super('run','POST', domain, task);
    }
}

export default RunTaskRequest;